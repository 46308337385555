/*! _footer.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Footer styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Light Footer
=============================================================================
***/

/* ==========================================================================
0. Light Footer
========================================================================== */
.footer-light {
    padding: 4rem 1.5rem;

    //Logo
    .footer-logo {
        height: 35px;
    }

    //Text
    .footer-text {
        font-size: 12px;
        color: $title-grey;
        margin-top: 0;
        margin-bottom: 0;
    }

    //Navigation
    .footer-nav-right {
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;

        .footer-nav-link {
            color: $blue-grey;
            margin-left: 25px;
            padding: 16px 0;
            display: inline-block;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;

            &:hover {
                color: $title-grey;
                border-color: $title-grey;
            }

            &.is-active {
                color: $secondary;
                border-color: $secondary;
            }
        }
    }
}