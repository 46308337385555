/*! _pricing.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Pricing tables styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Pricing card
=============================================================================
***/

/* ==========================================================================
0. Pricing card
========================================================================== */
.pricing-table {
    .pricing-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .pricing-plan {
            margin: 10px;
        }
    }

    .pricing-plan {
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:hover {
            border-color: $white;
            box-shadow: 0 5px 20px 0 $shadow;

            .plan-icon-container {
                box-shadow: 0 5px 20px 0 $shadow;
            }
        }

        //plan name
        .plan-name {
            font-weight: 600;
            font-size: 1.2rem;
            color: $blue-grey;
            text-transform: uppercase;
            padding-bottom: 15px;
        }

        //icon
        .plan-icon-container {
            width: 90px;
            height: 90px;
            margin: auto;
            border-radius: 50%;
            border: 1px solid $light-grey;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;

            .plan-icon {
                width: 50px;
                height: 50px;
            }
        }

        //price
        .plan-price {
            font-weight: 600;
            font-size: 50px;
            color: $blue-grey;
            text-align: center;
            padding: 20px 5px;
            margin: 0 auto;

            .price {
                position: relative;

                span {
                    font-size: 18px;
                    top: 14px;
                    position: absolute;

                    &.dollar {
                        left: -12px;
                    }

                    &.cents {
                        right: -12px;
                    }
                }
            }

            .price-billed {
                font-size: 12px;
                margin-left: -11px;
            }
        }

        //features
        .plan-items {
            text-align: center;

            //item
            .plan-item {
                font-size: 14px;
                color: $dark-grey;
                margin: 15px 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                b {
                    color: $blue-grey;
                    margin-right: 5px;
                }
            }
        }

        //Horizontal plan style
        &.pricing-plan-horizontal {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
        }

        .plan-multi-columns {
            display: flex;
        }
    }
}