/*! _navbar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Navbar related styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Navbar
1. Hamburger menu
=============================================================================
***/

/* ==========================================================================
0. Navbar
========================================================================== */
.navbar-wrapper {
    width: 100%;
    height: 4.6rem;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.3s;
    position: fixed;

    &.is-semisolid {
        background-color: $white !important;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) !important;
    }

    //Brand
    .navbar-brand {
        .navbar-logo {
            height: 28px;

            &.light-logo {
                display: block;
            }

            &.dark-logo {
                display: none;
            }
        }
    }

    //Item
    .navbar-item {
        color: $blue-grey;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s;

        &.is-slide {
            &:after {
                content: '';
                display: block;
                height: 1px;
                width: 0;
                min-width: 0;
                left: 0;
                bottom: 0;
                background-color: $white;
                transition: width 0.3s;
                position: absolute;
            }

            &:hover {
                &:after {
                    width: 100%
                }
            }
        }

        &:hover {
            color: $secondary;
            background-color: transparent;
        }
    }

    //Menu burger
    .custom-burger {
        margin-left: auto;
        display: none;
        justify-content: flex-end;
        align-items: center;
        width: 3rem;
        height: 4.6rem;
    }

    //Light navbar
    &.navbar-light {
        .navbar-item {
            color: $white;
        }
    }

    //Active transparent navbar modifiers
    &.navbar-faded {
        background-color: $white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

        //Brand
        .navbar-brand {
            .navbar-logo {
                &.light-logo {
                    display: none;
                }

                &.dark-logo {
                    display: block;
                }
            }
        }

        //Item
        .navbar-item {
            &.is-slide {
                &:after {
                    background-color: $white;
                }
            }
        }

        //Burger
        .icon-box-toggle {
            .icon-line {
                background-color: $blue-grey;
            }
        }
    }

    //Navbar on light bg
    &.navbar-transparent {
        //Item
        .navbar-item {
            &.is-slide {
                &:after {
                    background-color: $blue-grey;
                }

                &:hover {
                    color: $blue-grey;
                }
            }
        }

        //Burger
        .icon-box-toggle {
            .icon-line {
                background-color: $blue-grey;
            }
        }
    }
}

/* ==========================================================================
1. Hamburger menu
========================================================================== */
.icon-box-toggle {
    width: 25px; 
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;

    &.active {
        .icon-line {
            background-color: $light-black;
        }
    }

    &.active > .icon-line-top {
        top: 50%;
        transform: translate(0, -50%) rotate(45deg);
    }

    &.active > .icon-line-center {
        opacity: 0;
        visibility: hidden; 
    }

    &.active > .icon-line-bottom {
        top: 50%;
        transform: translate(0, -50%) rotate(135deg);
    }
}

//Icon
.icon-line {
    width: 25px;
    height: 2px;
    background-color: $white;
    transition: all .3s ease;
    position: absolute;
}

//Icon top
.icon-line-top {
    top: 12px;
}

//Icon bottom
.icon-line-bottom {
    top: 26px;
}
