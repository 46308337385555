/*! _auth.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Login and Sign up shared styles 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Cards
1. Links
2. Others
=============================================================================
***/

/* ==========================================================================
0. Cards
========================================================================== */
.clean-login-card,
.clean-signup-card {
    padding: 40px;
    border-radius: 6px;
    max-width: 400px;
    margin: auto;
    background-color: $white;
    box-shadow: 0 5px 30px 0 darken($light-blue-grey, 2%);

    //Title
    .card-title {
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: $blue-grey;
        padding: 0 0 20px 0;
    }
}

.clean-signup-card {
    margin-left: auto;
    margin-right: 0;
}

.clean-signup-info {
    padding: 40px;
    max-width: 400px;
    margin-bottom: 0;

    .card-title {
        font-weight: 500;
        font-size: 25px;
        color: $blue-grey;
        margin-bottom: 20px;
    }
    
    //Features
    .feature {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        .feature-img {
            height: 60px !important;
            width: 60px !important;
        }

        .feature-text {
            margin-left: 20px;
            color: $grey-9;
        }

    }
}

/* ==========================================================================
1. Links
========================================================================== */
.clean-link {
    color: $blue-grey;
    margin-top: 15px;
    display: block;
    transition: color 0.3s;

    &:hover {
        color: $secondary;
    }   
}

/* ==========================================================================
2. Others
========================================================================== */
.button.button-auth {
    height: 46px;
}

//Checkbox
.checkbox-wrap {
    color: $title-grey;
    margin-bottom: 10px;

    span {
        position: relative;
        top: -2px;
    }
}
