/*! _sections.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Sections related styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Sections general styles
=============================================================================
***/

/* ==========================================================================
0. Sections general styles
========================================================================== */
.section {
    background-color: $white;

    //Section colors
    &.section-light-grey {
        background-color: $light-grey;

        &.is-skewed-sm::before {
            background-color: $light-grey;
        }
    }

    &.section-feature-grey {
        background-color: $section-grey;

        &.is-skewed-sm::before {
            background-color: $section-grey;
        }
    }

    &.section-primary {
        background-color: $primary;

        &.is-skewed-sm::before {
            background-color: $primary;
        }
    }

    &.section-secondary {
        background-color: $secondary;

        &.is-skewed-sm::before {
            background-color: $secondary;
        }
    }
    
    //Slanted section
    &.is-skewed-sm {
        overflow: visible;
        z-index: 1;
        padding-top: 80px;
        padding-bottom: 160px;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $white;
            transform-origin: 0 0;
            transform: skew(0deg, -4deg);
            z-index: -1;
            position: absolute;
        }
    }

    //Content spacing
    .content-wrapper {
        padding-top: 40px;
    }

    //Title wrapper
    .section-title-wrapper {
        padding: 40px 20px;
        position: relative;

        .bg-number {
            font-size: 150px;
            font-weight: bolder;
            color: $light-grey;
            text-align: center;
            width: 150px;
            height: 150px;
            line-height: 150px;
            top: -18px;
            margin-left: auto;
            margin-right: auto;
            opacity: 0.5;
            left: 0;
            right: 0;
            z-index: -1;
            position: absolute;
        }
    }

    //Title
    .section-title {
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 700;
        font-size: 2.6rem;
        color: $blue-grey;
        line-height: 45px;
        position: relative;

        &::before,
        &::after {
            content: '';
            width: 70px;
            height: 4px;
            background-color: $fade-grey;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
        }

        &::before {
            left: 15%;
            z-index: -999;
        }

        &::after {
            right: 15%;
            z-index: -999;
        }
    }
     
    //Subtitle
    .section-subtitle {
        font-family: 'Nexa Light', sans-serif;
        font-size: 1rem;
        color: $title-grey;
    }
    
    //Feature heading
    .feature-heading {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2rem;
        color: $primary;
        margin-bottom: 30px;
    }

    //Feature text
    .feature-text {
        line-height: 25px;
    }
}

#pricing-section {
    padding-bottom: 80px;
}