/*! _messages.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Messages styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. GGtooltips
=============================================================================
***/

/* ==========================================================================
0. GGtooltips
========================================================================== */
.tt {
    padding: 6px;
    cursor: pointer;
    color: $secondary;
}

.ggtooltip {
    font-weight: 500;
    z-index: 1030;
    display: block;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: visible;
    position: absolute;

    &.fade {
        opacity: 0;
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
        
        &.in {
            opacity: 1;
        }
    }

    &.in {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }

    &.top {
        margin-top: -15px;

        .ggtooltip-arrow,
        .ggtooltip-arrow-shadow {
            bottom: 1px;
            left: 50%;
            margin-left: -5px;
            border-top-color: #222 !important;
            border-width: 5px 5px 0;
        }

        .ggtooltip-arrow-shadow {
            bottom: 0;
        }
    }

    &.right {
        margin-left: 15px;

        .ggtooltip-arrow,
        .ggtooltip-arrow-shadow {
            top: 50%;
            left: 1px;
            margin-top: -5px;
            border-right-color: #222 !important;
            border-width: 5px 5px 5px 0;
        }

        .ggtooltip-arrow-shadow {
            left: 0;
        }
    }

    &.bottom {
        margin-top: 15px;

        .ggtooltip-arrow,
        .ggtooltip-arrow-shadow {
            top: 1px;
            left: 50%;
            margin-left: -5px;
            border-bottom-color: #222 !important;
            border-width: 0 5px 5px;
        }

        .ggtooltip-arrow-shadow {
            top: 0;
        }
    }

    &.left {
        margin-left: -15px;

        .ggtooltip-arrow,
        .ggtooltip-arrow-shadow {
            top: 50%;
            right: 1px;
            margin-top: -5px;
            border-left-color: #222 !important;
            border-width: 5px 0 5px 5px;
        }

        .ggtooltip-arrow-shadow {
            right: 0;
        }
    }

    .ggtooltip-inner {
        max-width: 200px;
        padding: 8px 12px;
        color: #ffffff !important;
        text-align: center;
        text-decoration: none;
        background-color: #222 !important;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-shadow: 0 3px 10px 4px rgba(0,0,0,.04)
    }

    .ggtooltip-arrow,
    .ggtooltip-arrow-shadow {
        width: 0;
        height: 0;
        border-color: transparent !important;
        border-style: solid;
        position: absolute;
    }
}