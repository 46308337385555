/*! _responsive.scss | Bulkit | CSS Ninja */

/* ==========================================================================
General responsive styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. max-width 480px
1. max-width 768px
2. Between 768px and 1024px portrait orientation
3. max-width: 1024px
4. max-width: 1087px
=============================================================================
***/

/* ==========================================================================
0. max-width 480px
========================================================================== */
@media (max-width: 480px) {
    //Grouped fields
    .field-grouped {
        display: block;

        .cta-input {
            width: 100% !important;
        }

        .button-hero-form,
        .button-cta-form {
            width: 100%;
            margin-top: 15px;
            margin-left: 0;
        }
    }
}

/* ==========================================================================
1. max-width 768px
========================================================================== */
@media (max-width: 768px) {
    //Hero body
    .hero-body {
        .title {
            text-align: center;
            padding: 0 10px;
        }

        .subtitle {
            text-align: center;
        }

        .clean-hero-mockup {
            margin-top: 10px;
        }

        .image {
            margin-bottom: -40px;
        }
    }

    //Navbar
    .navbar-wrapper {
        &.is-dark-mobile {
            background-color: $white;

            //Brand
            .navbar-brand {
                .navbar-logo {
                    &.light-logo {
                        display: none;
                    }

                    &.dark-logo {
                        display: block;
                    }
                }
            }
        }

        & > .container {
            display: block !important;
        }

        //Navbar burger
        .custom-burger {
            display: flex;
        }

        //Navbar menu
        .navbar-menu { 
            background-color: $white !important;
            display: block !important;
            visibility: hidden;
            opacity: 0;
            padding: 0 20px 20px 20px !important;
            box-shadow: 0 10px 15px $base-shadow !important;

            &.is-active {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.3s, visibility 0.3s;
            }

            //Items
            .navbar-item {
                color: $blue-grey;
                text-align: center;
                line-height: 3.5rem;
                padding: 0;
                display: block;

                &.is-slide {
                    &::after {
                        background: transparent;
                    }
                }

                &:hover {
                    color: $secondary !important;
                }
            }

            //Buttons
            .button {
                width: 100%;     

                &.button-signup {
                    color: $light-black;

                    &:hover {
                        color: $secondary;
                    }
                }

                &.button-createlink {
                    color: $white;
                    border-color: $secondary;
                    background-color: $secondary;

                    &:hover {
                        color: $secondary;
                        background-color: transparent;
                    }
                }
            }
        }

        .navbar-start,
        .navbar-end {
            display: block !important;
        }
    }

    //Section
    .section {
        //section title
        .section-title-wrapper {
            padding: 40px 0 !important;
        }

        .section-title {
            &::before {
                width: 40px;
                left: 5%;
            }

            &::after {
                width: 40px;
                right: 5%;
            }
        }

        .content-wrapper {
            .content {
                text-align: center;
            }
        }
    }

    //Cta form input
    .input.cta-input {
        width: 300px;
    }

    //Shorten form buttons
    .button {
        &.button-shorten-form {
            margin-top: 10px;
            margin-left: 0;
            width: 100%;
        }

        &.button-shorten-form-footer {
            width: 100%;
        }
    }

    //Shorten form footer
    .form-footer {
        display: block;
    }

    //Cards
    .flex-card {
        &.icon-card-hover {
            padding: 15px 15px 15px 100px !important;
            height: auto;
        }

        &.clean-signup-card {
            margin: auto;
        }
    }
    
    #signup-card {
        text-align: center;
    }

    //Pricing table
    .pricing-table {
        .pricing-container {
            .pricing-plan {
                width: 100% !important;
            }
        }
    }  

    //Footer
    .footer-light {
        text-align: center;

        //Navigation
        .footer-nav-right {
            display: block;

            .footer-nav-link {
                margin: 0 10px;
            }
        }
    }
}

/* ==========================================================================
2. Between 768px and 1024px portrait orientation
========================================================================== */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
    //tablet portrait orientation adjustments
    .columns {
        padding: 0 10%;
    }

    .content-wrapper {
        padding-top: 0 !important;
    }
}

/* ==========================================================================
3. max-width: 1024px
========================================================================== */
@media (max-width: 1023px) {
    //Pricing table
    .pricing-table {
        .pricing-container {
            flex-wrap: wrap;
        
            .pricing-plan {
                width: calc(50% - 20px);
            }
        }
    }  
}

/* ==========================================================================
4. max-width: 1087px
========================================================================== */
@media (max-width: 1087px) {
    //Navbar
    .navbar-wrapper {
        display: flex;
        background-color: red;

        & > .container {
            display: flex;
            justify-content: space-between;
        }

        //Navbar menu
        .navbar-menu {
            width: 100%;
            padding: 0 20px 0 0;
            justify-content: space-between;
            align-items: center;
            display: flex;
            box-shadow: none;
            background-color: transparent;
        }

        .navbar-start,
        .navbar-end {
            height: 100%;
            display: flex;
            align-items: center;
        }

        //Brand
        .navbar-brand {
            padding: 0 20px;
            height: 4.6rem;
        }
    }

    //Hero body
    .hero-body {
        padding-top: 50px;
    }

    //Pricing table
    .pricing-table {
        .pricing-plan {
            .plan-multi-columns {
                display: block !important;
            }
        }
    }
}