/*! _layout-utils.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulkit utils
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Resets and base setup
1. Back to top button
2. Progress bars
3. Input placeholders
4. Accessibility
=============================================================================
***/

/* ==========================================================================
0. Resets and base setup
========================================================================== */
body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    overflow: hidden !important;
}

@font-face {
    font-family: 'Nexa Light';
    src: url("../fonts/nexa/NexaLight.woff") format("woff"), url("../fonts/nexa/NexaLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Nexa Bold';
    font-weight: bold;
    src: url("../fonts/nexa/NexaBold.woff") format("woff"), url("../fonts/nexa/NexaBold.ttf") format("truetype");
}

/* ==========================================================================
1. Back to top button
========================================================================== */
#backtotop {
    visibility: hidden;
    margin: 0 25px 0 0;
    z-index: 9999;
    bottom: 25px;
    right: 0;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0, 1.4, 1, 1.4);
    transform: scale(0.7);
    position: fixed;

    span {
        font-size: 40px;
        color: $secondary;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        opacity: 1;
        border-radius: 50%;
        cursor: pointer;
        transition: color 0.3s;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);

        span:hover {
            color: $primary;
        }
    }
}

/* ==========================================================================
2. Progress bars
========================================================================== */
.progress {
    width: 100% !important;
}

.progress.primary::-webkit-progress-value {
    background-color: $primary !important;
}

.progress.secondary::-moz-progress-bar {
    background-color: $secondary !important;
}

/* ==========================================================================
3. Input placeholders
========================================================================== */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}

/* ==========================================================================
4. Accessibility
========================================================================== */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}