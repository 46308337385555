/*! themes/_colors.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulkit general color variables 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Bulkit colors
1. State colors
2. Predefined box shadows
=============================================================================
***/

/* ==========================================================================
0. Bulkit colors
========================================================================== */
$ultra-light-blue-grey: #edf0f9;
$light-grey: #e6eaed;
$light-black: #4a4a4a;
$light-blue-grey: #e5eaf6;
$white: #fff;
$smoke-white: #f7f7f7;
$grey-white: #f2f2f2;
$title-grey: #a9abac;
$blue-grey: #444F60;
$fade-grey: #ededed;
$grey-6: #666;
$grey-9: #999;
$dark-grey: #737373;
$default-input: #ccc;
$coal: #444;
$black: #363636;
$placeholder: #cecece;
$section-grey: #fafafa;
$shadow: #dde4f3;

/* ==========================================================================
1. State colors
========================================================================== */
$red: #FF2C57;
$blue: #039BE5;
$green: #23d160;
$orange: #eda514;

/* ==========================================================================
2. Predefined box shadows
========================================================================== */

$base-shadow: rgba(0, 0, 0, 0.12);

//Orange box shadow
$orange-shadow-from: rgba(237, 165, 20, 0.42);
$orange-shadow-to: rgba(237, 165, 20, 0.2);
$orange-box-shadow:  0 14px 26px -12px $orange-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $orange-shadow-to !important;

//Green box shadow
$green-shadow-from: rgba(0, 178, 137, 0.42);
$green-shadow-to: rgba(0, 178, 137, 0.2);
$green-box-shadow:  0 14px 26px -12px $green-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $green-shadow-to !important;

//Red box shadow
$red-shadow-from: rgba(255, 114, 115, 0.42);
$red-shadow-to: rgba(255, 114, 115, 0.2);
$red-box-shadow:  0 14px 26px -12px $red-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $red-shadow-to !important;

//Blue box shadow
$blue-shadow-from: rgba(3, 155, 229, 0.42);
$blue-shadow-to: rgba(3, 155, 229, 0.2);
$blue-box-shadow:  0 14px 26px -12px $blue-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $blue-shadow-to !important;