/*! _cards.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Card styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Flex card
=============================================================================
***/

/* ==========================================================================
0. Flex card
========================================================================== */
.flex-card {
    background-color: #fff;
    border: 1px solid $light-grey;
    border-radius: 0.1875rem;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    transition: all 0.3s;
    position: relative;

    //card title
    .card-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-decoration: none;
    }

    //card-description
    .card-description {
        font-size: 14px;
        line-height: 24px;
        cursor: default;
        color: $grey-6;
    }

    //icon card variation
    &.icon-card {
        //link
        .icon-card-link {
            color: $light-black;
            display: block;
            transition: all 0.3s;

            &:hover,
            &:focus {
                color: $title-grey;
            }
        }

        //image
        .icon-card-image {
            max-height: 100px;
        }

        &:hover,
        &:focus {
            border-color: $white;
            box-shadow: 0 5px 20px 0 $shadow;
        }
    }

    &.icon-card-hover {
        text-align: left;
        height: 150px;
        padding: 10px 10px 10px 130px;
        background-color: #fff;
        background-position: 0 0, -80px 20px;
        background-size: auto, 200px;
        background-repeat: no-repeat;
        transform: translate(0,0);
        transition: all 0.3s ease;

        &:hover {
            border-color: $white;
            box-shadow: 0 5px 30px 0 $shadow;
            background-position: 0 0, -80px -25px;
        }

        &.first-card {
            background-image: linear-gradient(180deg, rgba($white, 0), rgba($white, 0)), url(../images/icons/chrono.svg);
        }

        &.second-card {
            background-image: linear-gradient(180deg, rgba($white, 0), rgba($white, 0)), url(../images/icons/tablet-dots.svg);
        }

        &.third-card {
            background-image: linear-gradient(180deg, rgba($white, 0), rgba($white, 0)), url(../images/icons/cog.svg);
        }

        &.fourth-card {
            background-image: linear-gradient(180deg, rgba($white, 0), rgba($white, 0)), url(../images/icons/plane.svg);
        }
    }

    //card overflow
    &.card-overflow {
        overflow: visible !important;
    }
}