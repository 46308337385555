/*! _forms.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Form inputs styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Form
1. Label
2. Text inputs
3. Textareas
4. Selects
5. Input wrappers
6. Material Inputs
7. Switch
8. Checkbox
=============================================================================
***/

/* ==========================================================================
0. Form
========================================================================== */
.form-footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

/* ==========================================================================
1. Label
========================================================================== */
.field-label .label,
.label {
    font-size: 1rem;
    color: $blue-grey;
    margin-bottom: 5px !important;
}

/* ==========================================================================
2. Text inputs
========================================================================== */
.input {
    font-size: 1rem;
    color: $light-black;
    height: 40px;
    box-shadow: none !important;
    border-color: $light-grey;
    transition: all 0.3s;

    //focus colors
    &.is-primary-focus:focus {
        border-color: $primary;
    }

    &.is-secondary-focus:focus {
        border-color: $secondary;
    }

    &.hero-input {
        height: 40px;
        border-color: $white;
    }

    //default
    &:hover,
    &:focus,
    &:active {
        border-color: $light-grey;
    }
}

/* ==========================================================================
3. Textareas
========================================================================== */
.textarea {
    font-size: 1rem;
    color: $light-black;
    box-shadow: none !important;
    transition: all 0.3s;

    //focus colors
    &:focus {
        border: 1px solid $fade-grey;
    }

    &.is-primary-focus:focus {
        border-color: $primary;
    }

    &.is-secondary-focus:focus {
        border-color: $secondary;
    }

    &.is-default-focus:focus {
        border-color: $default-input;
    }
}

/* ==========================================================================
4. Selects
========================================================================== */
.select {
    width: 100%;
    height: 40px !important;

    &::after {
        border-color: $blue-grey !important;
    }

    select {
        width: 100%;
        height: 40px;
        border-color: $light-grey;
        box-shadow: none !important;
        transition: all 0.3s;

        &:hover {
            border-color: $light-grey;
        }

        &:active,
        &:focus {
            border-color: $secondary;
            box-shadow: none;
        }
    }
}

//Custom select
.chosen-container {
    outline: 0;

    //selected item
    .chosen-single {
        padding-left: 8px;
        border-color: $light-grey;
        transition: border-color 0.3s;

        span {
            font-size: 1rem;
            color: $light-black;
        }

        span ~ div {
            &::after {
                display: none;
            }
        }
    }

    //results
    .chosen-results {
        max-height: 120px;

        .active-result {
            color: $light-black;
            padding: 8px 0;

            &.highlighted {
                color: $secondary;
            }
        }

        .no-results {
            padding: 8px 0;
        }
    }

    //drop
    &.chosen-with-drop {
        .chosen-single {
            border-color: $secondary;
        }
    }

    //drop container
    .chosen-drop {
        border-color: $secondary;
    }
}

/* ==========================================================================
5. Input wrappers
========================================================================== */
.control {
    &.control-fullwidth {
        width: 100%;
    }
}

//Field
.field {
    margin-bottom: 20px !important;
}

//Grouped fields
.field-grouped {
    display: flex;
    justify-content: center;

    .is-expanded {
        width: 100%;
    }

    //input
    .cta-input {
        width: 400px;
        height: 40px;
    }

    //buttons
    .button-hero-form,
    .button-cta-form {
        margin-left: 10px;
        margin-top: 0;
    }
}

/* ==========================================================================
6. Material Inputs
========================================================================== */
.control-material {
    position: relative; 
    margin-bottom: 3.4em;

    //primary colors
    &.is-primary {
        .bar:before,
        .bar:after {
            background: $primary;
        }
    }

    //secondary colors
    &.is-secondary {
        .bar:before,
        .bar:after {
            background: $secondary;
        }
    }

    //material styles
    .material-input,
    .textarea {
        width: 100% !important;
        padding: 8px;
        display: block;
        border: none;
        border-bottom: 1px solid $title-grey;
    	border-radius: 0;
        background-color: transparent !important;

        &:focus {
            outline: none;
        }
    }

    .material-input:focus ~ .bar:before,
    .material-input:focus ~ .bar:after,
    .textarea:focus ~ .bar:before,
    .textarea:focus ~ .bar:after {
        width: 50%;
    }

    //Floating label
    .label {
        font-family: 'Nexa Light', sans-serif;
        font-weight: normal;
        font-size: 1rem;
        color: $placeholder; 
        pointer-events: none;
        left: 6px;
        top: 6px;
        transition: all 0.2s ease; 
        position: absolute;

        &.label-toggled {
            font-size: 0.9rem;
            color: $secondary; 
            top: -18px;
            left: 0; 
        }

        &.label-primary {
            &.label-toggled {
                color: $primary;
            }
        }
    }

    //bottom bar
    .bar {
        position: relative;
        display: block;
    }

    .bar:before, .bar:after {
        content:'';
        height: 1px; 
        width: 0;
        bottom: 0px; 
        transition: all 0.2s ease; 
        position: absolute;
    }

    .bar:before {
        left: 50%;
    }

    .bar:after {
        right: 50%; 
    }
}

/* ==========================================================================
7. Switch
========================================================================== */
.switch-input {
    display: none;
}

.switch-label {
    font-weight: 700;
    color: $blue-grey;
    display: inline-block;
    cursor: pointer;
    padding: 10px 60px 10px 0;
    position: relative;
}

.switch-label:before,
.switch-label:after {
    content: '';
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
    position: absolute;
}

.switch-label:before {
    right: 0;
    width: 44px;
    height: 20px;
    background-color: $light-grey;
    border-radius: 40px;
}

.switch-label:after {
    right: 24px;
    width: 18px;
    height: 18px;
    background-color: $white;
    border-radius: 50%;
}

.switch-input:checked + .switch-label:before {
    background-color: $secondary;
}

.switch-input:checked + .switch-label:after {
    transform: translate(23px, -50%);
}

/* ==========================================================================
8. Checkbox
========================================================================== */
.checkbox-wrap {
    cursor: pointer;
    padding: 1em 1em 1em 0;
    position: relative;

    &::selection {
        background: transparent;
    }

    //d-checkbox
    .d-checkbox + span {
        content: '';
        width: 2em;
        height: 2em;
        display: inline-block;
        margin: 0 .5em 0 0;
        padding: 0;
        border: 1px solid $grey-9;
        border-radius: 2px;
        vertical-align: middle;
        background-color: $white;

        &::after {
            content: '';
            display: block;
            transform: scale(0);
            transition: transform .2s;
        }
    }

    //checked state
    .d-checkbox:checked + span::after {
        transform: scale(1);
    }

    //hide native input
    .d-checkbox {
        position: absolute;
        cursor: pointer;
        opacity: 0;
    }

    //check symbol
    .d-checkbox + span {
        width: 1.4em;
        height: 1.4em;

        &::after {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
            background-size: contain;
            width: 1.3em;
            height: 1.3em;
        }
    }
} 