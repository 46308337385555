/*! themes/_green.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Green theme variables and styles (Green theme is used in "Landing kit v2" )
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Theme specific Hero
=============================================================================
***/

/* ========================================================================== 
0. Theme color variables
========================================================================== */
$primary: #7e23ff;
$secondary: #039be5;

/* ==========================================================================
1. Theme Gradient
========================================================================== */
$hero-gradient: linear-gradient(to right, $primary, $secondary); 

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba($primary, 0.42);
$primary-shadow-to: rgba($primary, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba($secondary, 0.42);
$secondary-shadow-to: rgba($secondary, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;