/*! _animations.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Core animation styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================]
0. Default settings
1. Spin
2. Pulse
3. Width
4. Translate Left
5. Translate Down
6. Fade In
7. Fade In Left
8. Fade In Right
9. Fade In Up
10. Fade In Down

=============================================================================
***/

/* ==========================================================================
0. Default settings
========================================================================== */
.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

/* ==========================================================================
1. Spin
========================================================================== */

//Keyframes
@keyframes spin { 
    from {
         transform: rotate(0deg); 
    }
    to {
         transform: rotate(360deg); 
    }
}

/* ==========================================================================
2. Pulse
========================================================================== */

//Keyframes
@keyframes pulse { 
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.1); 
    }
    100% { 
       transform: scale(1); 
    } 
}

/* ==========================================================================
3. Width
========================================================================== */

//Keyframes
@keyframes width { 
    from {
        width: 100%; 
    }
    to {
        width: 0; 
    }
}
/* ==========================================================================
4. Translate Left
========================================================================== */

//Keyframes
@keyframes translateLeft {
    from {
        transform: translate3d(100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

//Settings
.translateLeft {
    animation-name: translateLeft;
    animation-duration: 0.3s;
    transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

/* ==========================================================================
5. Translate Down
========================================================================== */

//Keyframes
@keyframes translateDown {
    from {
        transform: translate3d(0, -100px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

//Settings
.translateDown {
    animation-name: translateDown;
    animation-duration: 0.3s;
    transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

/* ==========================================================================
6. Fade In
========================================================================== */

//Keyframes
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

//Settings
.fadeIn {
    opacity: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/* ==========================================================================
7. Fade In Left
========================================================================== */

//Keyframes
@keyframes fadeInLeft {
    from {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

//Settings
.preFadeInLeft {
    opacity: 0;
}

.fadeInLeft {
    opacity: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;    
}

/* ==========================================================================
8. Fade In Right
========================================================================== */

//Keyframes
@keyframes fadeInRight {
    from {
        transform: translate3d(-20px, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

//Settings
.preFadeInRight {
    opacity: 0;
}

.fadeInRight {
    opacity: 0;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

/* ==========================================================================
9. Fade In Up
========================================================================== */

//Keyframes
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 20px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

//Settings
.preFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* ==========================================================================
10. Fade In Down
========================================================================== */

//Keyframes
@keyframes fadeInDown {
    from {
        transform: translate3d(0, -20px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

//Settings
.preFadeInDown {
    opacity: 0;
}

.fadeInDown {
    opacity: 0;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}