/*! _hero.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Hero related styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero general styles
1. Hero body 
=============================================================================
***/

/* ==========================================================================
0. Hero general styles
========================================================================== */
.hero {
    padding-top: 4.6rem;

    //Primary background
    &.is-theme-primary {
        background-color: $primary;
    }

    //Secondary background
    &.is-theme-secondary {
        background-color: $secondary;
    }

    //Light grey background
    &.is-light-grey {
        background-color: $light-grey;
        background-image: -webkit-linear-gradient(309deg, #d0e0ec 0%, #f5f7fa 71%, white 100%);
        background-image: linear-gradient(141deg, #d0e0ec 0%, #f5f7fa 71%, white 100%);

        .title {
            color: $blue-grey;
        }

        .subtitle {
            margin-top: 0 !important;
            padding-right: 20%;
            line-height: 1.6 !important;
        }
    }
}

/* ==========================================================================
1. Hero body 
========================================================================== */
.hero-body {
    //Title
    .title {
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 700;
        font-size: 3.4rem;
        color: $white;
        margin-bottom: 25px;
    }

    //Subtitle
    .subtitle {
        color: $white;
    }

    //Cta wrapper
    .cta-wrapper {
        padding: 25px 0;
    }

    //Hero mockup position
    .clean-hero-mockup {
        margin-top: 50px;
        z-index:2;
    }
}