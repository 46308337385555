/*! _buttons.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Button styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Main button class
1. Primary button
2. Secondary button
3. Success button
4. Warning button
5. Danger button
6. Info button
=============================================================================
***/

/* ==========================================================================
0. Main button class
========================================================================== */
.button {
    outline: none;
    transition: all 0.3s;

    //Signup button
    &.button-signup {
        color: $white;
        padding: 20px 15px;
        border-width: 2px;
        border-color: transparent;
        background-color: transparent;

        &:hover {
            color: $secondary;
            background-color: $white;
        }

        &.is-scroll {
            color: $light-black;

            &:hover {
                color: $secondary;
            }
        }
    }

    //Create link button
    &.button-createlink {
        font-weight: 600;
        color: $white;
        padding: 20px 30px;
        border-width: 2px;
        border-color: $white;

        &:hover {
            color: $secondary;
            background-color: $white;
        }

        &.secondary-btn {
            &::hover {
                background-color: $secondary;
            }
        }
    }
    
    //Hero form button
    &.button-hero-form {
        font-weight: 600;
        color: $white;
        width: 100%;
        height: 40px;
        padding: 0 30px;
        margin-top: 10px;
        border-width: 0;
        background-color: $primary;

        &:hover {
            color: $blue-grey;
            background-color: $white;
        }
    }

    //Cta button
    &.button-cta {
        font-weight: 600;
        color: $white;
        padding: 24px 30px;

        &:hover,
        &:focus {
            color: $blue-grey !important;
            background-color: $white !important;
            border-color: $white !important;
            box-shadow: 0 5px 30px 0 $shadow !important;
            transform: translateZ(0) translate(0, -5px);
        }
    }

    //Cta form button
    &.button-cta-form {
        font-weight: 600;
        width: 100%;
        height: 40px;
        padding: 0 30px;
        margin-top: 10px;

        &:hover,
        &:focus {
            color: $blue-grey !important;
            border-color: $title-grey !important;
            background-color: $white !important;
        }
    }

    //Pricing button
    &.button-pricing {
        font-weight: 600;
        padding: 20px 30px;
    }

    //Contact form button
    &.button-contact {
        height: 50px;
        padding: 0 40px;
    }

    //Shorten form button
    &.button-shorten-form {
        height: 40px;
        margin-left: 20px;

        &:focus {
            border-color: $default-input;
        }
    }

    //Shorten form footer button
    &.button-shorten-form-footer {
        height: 40px;
        padding: 0 60px;
    }

    //Rounded buttons
    &.rounded {
        border-radius: 500px;
    }

    //Outlined buttons
    &.btn-outlined {
        background-color: transparent;

        //Light buttons
        &.light-btn {
            border-color: $white;
            color: $white;

            &:hover {
                background-color: $white;
                color: $blue-grey;
            }
        }
    }

    //Default button
    &.default-btn {
        color: $placeholder !important;
    }

    //Link buttons
    &.is-link {
        text-decoration: none !important;
    }

    //Fullwidth class
    &.is-fullwidth {
        display: block !important;
        width: 100% !important;
        text-align: center !important;
    }

    &.is-active,
    &:active,
    &:focus {
        box-shadow: none !important;
    }

    //Default border
    &:focus {
        border-color: $white;
    }
}

/* ==========================================================================
1. Primary button
========================================================================== */
.button.primary-btn {
    color: $white;
    border-color: $primary;
    background-color: $primary;

    &.btn-outlined {
        border-color: $primary;
        color: $primary;
        background-color: transparent;

        &:hover {
            color: $white;
            background-color: $primary;
        }
    }

    &:hover {
        color: $white;
        background-color: darken($primary, 5%);
    }

    &:focus {
        border-color: $primary;
    }
}

/* ==========================================================================
2. Secondary button
========================================================================== */
.button.secondary-btn {
    color: $white;
    border-color: $secondary;
    background-color: $secondary;

    &.btn-outlined {
        border-color: $secondary;
        color: $secondary;
        background-color: transparent; 

        &:hover {
            color: $white;
            background-color: $secondary;
        }
    }

    &:hover {
        color: $white;
        background-color: darken($secondary, 5%);
    }

    &:focus {
        border-color: $secondary;
    }
}

/* ==========================================================================
3. Success button
========================================================================== */
.button.success-btn {
    color: $white;
    border-color: $green;
    background-color: $green;

    &.btn-outlined {
        border-color: $green;
        color: $green;
        background-color: transparent;

        &:hover {
            color: $white;
            background-color: $green;
        }
    }

    &:focus {
        border-color: $green;
    }
}

/* ==========================================================================
4. Warning button
========================================================================== */
.button.warning-btn {
    color: $white;
    border-color: $orange;
    background-color: $orange;

    &.btn-outlined {
        border-color: $orange;
        color: $orange;
        background-color: transparent;

        &:hover {
            color: $white;
            background-color: $orange;
        }
    }

    &:hover {
        color: $white;
    }

    &:focus {
        border-color: $orange;
    }
}

/* ==========================================================================
5. Danger button
========================================================================== */
.button.danger-btn {
    outline: none;
    color: $white;
    border-color: $red;
    background-color: $red;

    &.btn-outlined {
        border-color: $red;
        color: $red;
        background-color: transparent;
        &:hover {
            color: $white;
            background-color: $red;
        }
    }

    &:hover {
        color: $white;
    }
  
    &:focus {
        border-color: $red;
    }
}

/* ==========================================================================
6. Info button
========================================================================== */
.button.info-btn {
    outline: none;
    color: $white;
    border-color: $blue;
    background-color: $blue;

    &.btn-outlined {
        border-color: $blue;
        color: $blue;
        background-color: transparent;

        &:hover {
            color: $white;
            background-color: $blue;
        }
    }

    &:hover {
        color: $white;
    }

    &:focus {
        border-color: $blue;
    }
}